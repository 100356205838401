import { Col, Form, Row, Dropdown, MenuProps, Slider, Tooltip } from "antd";
import { FacetSelector } from "./FacetSelector";
import { TreeFacetSelector } from './TreeFacetSelector';
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { useState } from "react";
import { Preview } from "./Preview";
import { getDownloadUrl, queryHasCommoditionAggregateFilter } from "../utilities/download";
import "./MainControl.css"
import { SliderMarks } from "antd/es/slider";
import { Button } from 'antd';
import { Icon, Link, Stack } from "@fluentui/react";
import { Facet, IFacet, IFilter, ISelection, NotificationCallback } from "../types";
import DownloadLimit from "./DownloadLimit";
import config from "../config.json";

type AdditionalOptions = {
    name: string;
    htmlText: string;
    htmlTooltip: string;
    columnsToFetch: string[];
    filters: Omit<IFilter,'summarize'>[];
};

export function MainControls(props: { facets: IFacet[], apiKey: string, notify: NotificationCallback, downloadLimit: string }) {

    const [selection, setSelection] = useState<ISelection>({
        startYear: 2020,
        endYear: 2025,
        indicators: [],
        transformations: ["L"],
        limit: 0,
        filters: [],
        compression: "none"
    });
    const [treeSelectValues, setTreeSelectValues] = useState<string[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['BYMODE', 'BYCHAPTER']);

    let metadataFilters : Array<IFacet> = new Array<IFacet>();

    for (let i = 0; i < props.facets.length; i++) {
        if (props.facets[i].code === "hs4") {
            for (let j = 0; j < props.facets[i].options.length; j++) {
                if (props.facets[i].options[j].metadata !== null) {
                    var keys = Object.keys(props.facets[i].options[j].metadata);
                    var values = Object.values(props.facets[i].options[j].metadata);
                    for (let k = 0; k < keys.length; k++) {
                        if (values[k] !== null) {
                            var value = values[k].toString();
                            tryAddToCollection(keys[k], props.facets[i].options[j].code, value, metadataFilters)
                        }
                    }
                }
            }
        }
    }

    const facetRows = props.facets.concat(metadataFilters);

    const indicator = facetRows.find(x => x.code === "indicator")!;
    const transformation = facetRows.find(x => x.code === "transformation")!;
    const origin = facetRows.find(x => x.code === "origin")!;
    const destination = facetRows.find(x => x.code === "destination")!;
    const direction = facetRows.find(x => x.code === "direction")!;
    const mot = facetRows.find(x => x.code === "mot")!;    
    const hs4 = facetRows.find(x => x.code === "hs4")!;
    const aggregator : IFacet = facetRows.find(x => x.code === "commodity_aggregate")!;

    const menuClick: MenuClickEventHandler = ({ key }) => {
        startDownload({ ...selection, compression: key }, props.apiKey, props.downloadLimit);
    }

    const startCustomDownload = (name: string) => {
        // Copy selection
        let customSelection: ISelection = JSON.parse(JSON.stringify(selection));
        const additionalDownloadOptions = config.additionalDownloadOptions.find((c) => c.name === name);

        if(additionalDownloadOptions) {            
            customSelection = extendCustomSelection(additionalDownloadOptions, customSelection);
        }

        startDownload({ ...customSelection }, props.apiKey, props.downloadLimit); 
    }
      
    const items: MenuProps['items'] = [
        {
          label: indicator ? "Save as .csv" : "Save full dataset as .csv.gz",
          key: 'gz',
          onClick: menuClick
        }
    ];

    const onAfterChange = (value: number[]) => {
        if (value instanceof Array) {
            setSelection({ ...selection, startYear: value[0] || 2000, endYear: value[1] || 3000 });
    }
    };

    const marks: SliderMarks = {
        2000: '2000',
        2050: '2050',
      };

    marks[selection.startYear] = {
        style: {
          fontWeight: 'bold'
        },
        label: <em>{selection.startYear}</em>,
      };

    marks[selection.endYear] = {
        style: {
          fontWeight: 'bold'
        },
        label: <em>{selection.endYear}</em>,
      };

    var palantirRows = 
      <Stack horizontal wrap tokens={{ childrenGap: 2 }} styles={{ root: { 'box-shadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px', paddingBottom: '5px' }}}>    
        {facetRows
            .filter(f => f.name !== "Aggregate")
            .map(f => <FacetSelector isMulti facet={f} selection={selection} setSelection={setSelection} token={props.apiKey} toolTipTitle={f.name}/>
        )}        
        <Stack horizontal styles={{root: {paddingLeft: '45px'}}}>
            <Icon iconName="Download"/>
            <Link href="Data-dictionary-Usage.xlsx" target="_blank" download>Download Data Dictionary</Link>
        </Stack>
      </Stack>

    var prismRows = <>
        <Row>
            <Col span={6}/>
            <Col span={12}>
                <Row key='options'>
                    <Col span={20}>
                        <Form.Item
                            label={"Year Range"}
                            name={"year_range"}>
                            <Slider
                                 style={{marginLeft: "5%"}}
                                 marks={marks}
                                 range
                                 step={1}
                                 defaultValue={[2020, 2025]}
                                 min={2000}
                                 max={2050}
                                 onAfterChange={onAfterChange}
                            /> 
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <Button href="https://my.oxfordeconomics.com/reportaction/c03E837C8a7b4eDJ8a1467/Toc" className="glossary-link" target="_blank" rel="noreferrer">Glossary</Button>
            </Col>
        </Row>
        <br/>
        <Row key={`row_primary` }>
            <Col key={`col_1`} span={6}>
                <FacetSelector isMulti facet={origin} selection={selection} setSelection={setSelection} toolTipTitle={<p>"Reporter" is the country (or area) which reports the trade data.</p>}/>
            </Col>
            <Col key={`col_2`} span={6}>
                <FacetSelector isMulti facet={destination} selection={selection} setSelection={setSelection} toolTipTitle={<p>Refers to country (or countries) that trade with the selected reporting country (or countries) in TradePrism.</p>}/>    
            </Col>
            <Col key={`col_3`} span={6}>
                <FacetSelector 
                    isMulti
                    facet={direction} 
                    selection={selection} 
                    setSelection={setSelection} 
                    toolTipTitle={
                        <p>
                            Direction of trade.<br/>
                            - Imports refers to foreign goods transported into the home country which were produced in another country for sale or use. <br/>
                            - Exports are goods produced in the home country for sale/exchange to other markets.<br/>
                        </p>
                    }
                    />
            </Col>
            <Col key={`col_4`} span={6}>
                <FacetSelector isMulti facet={mot} selection={selection} setSelection={setSelection} toolTipTitle={<p>Mode of transport of trade</p>}/>
            </Col>
        </Row>

        <Row key='options'>
            <Col span={6}>
                {
                    !queryHasCommoditionAggregateFilter(selection) ?  
                        <TreeFacetSelector 
                            facet={hs4} 
                            selection={selection} 
                            setSelection={setSelection} 
                            values={treeSelectValues} 
                            setValues={setTreeSelectValues} 
                            expandedKeys={expandedKeys} 
                            setExpandedKeys={setExpandedKeys}/> 
                        :
                        null 
                    
                }
            </Col>
            <Col span={6}>
                <FacetSelector facet={aggregator} selection={selection} setSelection={setSelection} /> 
            </Col>
            <Col span={6}>
                <FacetSelector
                    isMulti
                    facet={indicator} 
                    selection={selection} 
                    setSelection={setSelection}
                    toolTipTitle={
                        <p>
                            Reflects unit of measurement.<br/>
                            - Mass Tonnes is unit of mass. Metric tonne (equivalent to 1,000 kilograms).<br/>
                            - Nominal values (USD) reflects the present value of goods/services exchanged in the marketplace in US Dollars.<br/>
                            - Real Value (2015 USD) of a product is its nominal value (in US Dollars) adjusted for inflation, enabling comparisons over a period of time. 
                            To convert a nominal value to real value, a base year (2015 as an example) is chosen arbitrarily and then a price index (GDP deflator) is used 
                            to convert the measurements so that they are measured in the money prevailing in the base year.
                        </p>
                    }
                    />
            </Col>
            <Col span={6}>
                <FacetSelector isMulti facet={transformation} selection={selection} setSelection={setSelection} />
            </Col>
        </Row>

    </>

    var formRows = indicator ? prismRows : palantirRows;

    return <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        >
        {formRows}        

        <center style= {{display: "flex", justifyContent:"center", flexDirection: 'row-reverse'}}>
            <Dropdown.Button
                style={{display: "flex", alignItems: "center", justifyContent: "center", float: "right", marginBottom: "2%", paddingTop: "1rem", width: "auto"}}
                size="large"
                type="primary"
                className="dropdown-button"
                menu={{ items }}
                onClick={() => startDownload(selection, props.apiKey, props.downloadLimit)}
            >
                { indicator ? "Save as .csv" : "Save full dataset as .csv"}
            </Dropdown.Button>
            {
                config.additionalDownloadOptions.map((item) => {
                    const { name, htmlTooltip, htmlText } = item;

                    return <Button
                        style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "2%", margin: "1rem", backgroundColor: '#003469' }}
                        size="large"
                        type="primary"
                        title= {htmlTooltip}
                        onClick={() => {
                            startCustomDownload(name);
                        }}
                    >
                        { htmlText }
                    </Button>
                })
            }   
        </center>
         <center>
            <DownloadLimit limit={props.downloadLimit} />
        </center> 
        <br />
        <Preview query={selection} token={props.apiKey} notify={props.notify}></Preview>
    </Form>;
}

function extendCustomSelection(additionalDownloadOptions: AdditionalOptions, customSelection: ISelection) :ISelection {
    for (let i = 0; i < additionalDownloadOptions.filters.length; i++) {

        const customFilter = additionalDownloadOptions.filters[i];

        // Check if facet was already applied to selection
        if (customSelection.filters.find((f) => f.facet === customFilter.facet)) {
            for (let j = 0; j < customSelection.filters.length; j++) {
                const selectionFilter = customSelection.filters[j];

                if (selectionFilter.facet === customFilter.facet) {
                    selectionFilter.type = customFilter.type;
                    selectionFilter.values = customFilter.values;
                }
            }
        } else {
            // Create new facet
            customSelection.filters.push({
                facet: customFilter.facet,
                values: customFilter.values,
                summarize: false,
                type: customFilter.type,
            });
        }
    }
    // Set SELECT fields
    customSelection.ColumnsToFetch = additionalDownloadOptions.columnsToFetch;

    return customSelection;
}

function tryAddToCollection(filter: string, code: string, value: string, collection: IFacet[]) {

    let existing = collection.find(x => x.name === filter);

    if (!existing) {
        existing = new Facet();
        existing.name = filter;
        existing.code = filter;
        existing.options = [{code: code, name: value, metadata: {}}];
        existing.showSummarize = false;
        
        collection.push(existing)
    }

    if (existing.options.find(x => x.name === value)) {
        if (existing.options.find(x => x.name === value)?.code !== code) {
            code = existing.options.find(x => x.name === value)?.code + "," + code;
                }

        const index = existing.options.findIndex(x => x.name === value);
                if (index > -1) {
            existing.options.splice(index, 1);
        }
    }     
}

function startDownload(selection: ISelection, apiKey: string, downloadLimit: string) {
    const limitedSelection = limitSelection(selection,downloadLimit);
    const downloadUrl = getDownloadUrl(limitedSelection, apiKey);
    window.location.assign(downloadUrl);
}

/**
 * Apply the download limit if the selection limit is set to 0
 * @param selection A selection to apply the download limit to
 * @param limit a download limit
 * @returns A selection with the download limit configured
 */
function limitSelection(selection: ISelection, limit: string): ISelection {

    const parsed = parseInt(limit);

    if(selection.limit === 0){
        return {
            ...selection,
            // use the parsed setting only if it resolves to a number
            limit: Number.isNaN(parsed) ? selection.limit : parsed
        }
    }

    return selection;
}